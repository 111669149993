import React from "react";
import ShowRarity from "./ShowRarity";
import LowResourceWarning from "./LowResources";

const ShowCards = ({ cardSet, clickHandler, lowResourceWarning }) => {
  return (
    <div>
      {lowResourceWarning ? (
        <LowResourceWarning />
      ) : (
        <div>
          <ShowRarity
            rarity="Legendary"
            cardSet={cardSet}
            clickHandler={clickHandler}
          />
          <ShowRarity
            rarity="Epic"
            cardSet={cardSet}
            clickHandler={clickHandler}
          />
          <ShowRarity
            rarity="Rare"
            cardSet={cardSet}
            clickHandler={clickHandler}
            lowResourceWarning={lowResourceWarning}
          />
          <ShowRarity
            rarity="Common"
            cardSet={cardSet}
            clickHandler={clickHandler}
          />
        </div>
      )}
    </div>
  );
};

export default ShowCards;
