import React, { useState } from "react";
import AddCrew from "./ShowCards/AddCrew";
import ShowCrew from "./ShowCards/ShowCrew";
import { useSelector, useDispatch } from "react-redux";
import { setBuildShipButton } from "../../../store";
import axios from "axios";
import { useAuth } from "../../../AuthContext";

const CurrentCard = ({
  allData,
  currentCard,
  closeCard,
  currentImagePath,
  shipData,
  maxCrew,
  crewCount,
  showAddCrewOption,
  setShowAddCrewOption,
  assignedPirates,
  setAssignedPirates,
  setRefreshData,
}) => {
  const [selectCrew, setSelectCrew] = useState(false);
  const [resetCounter, setResetCounter] = useState(false);
  const [showCrewMembers, setShowCrewMembers] = useState(false);
  const [buildShipResponse, setBuildShipResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const showBuildShipButton = useSelector((state) => state.showBuildShipButton);
  const { user, userData, apiUrl, setUpdateData } = useAuth();
  const dispatch = useDispatch();

  const buildShip = () => {
    const params = {
      hive_user_name: user,
      ship_data: shipData,
      assigned_pirates: assignedPirates,
    };

    const keychain = window.hive_keychain;
    const payload = {
      contractPayload: {
        shipData: assignedPirates,
        userResources: {
          wood: userData.wood,
          iron: userData.iron,
          copper: userData.copper,
        },
      },
    };

    keychain.requestBroadcast(
      user,
      [
        [
          "custom_json",
          {
            required_auths: [user],
            required_posting_auths: [],
            id: "piratesaga",
            json: JSON.stringify(payload),
          },
        ],
      ],
      "Active",
      (response) => {
        if (response.success === true) {
          axios
            .post(
              `${apiUrl}/create_ship/`,
              params, // Convert params to a JSON string
              {
                headers: {
                  // "X-CSRFToken": csrftoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              dispatch(setBuildShipButton(false));
              setBuildShipResponse(true);
              setResponseMessage(response.data.message);
              setRefreshData((prevData) => !prevData);
              setUpdateData((prevData) => !prevData);
            })
            .catch((error) => {
              dispatch(setBuildShipButton(false));
              setBuildShipResponse(true);
              setResponseMessage(error.response.data.message);
            });
        }
      }
    );
  };

  const addCrew = () => {
    setSelectCrew(true);
  };

  const closeButton = () => {
    setSelectCrew(false);
    setResetCounter(true);
    setTimeout(() => setResetCounter(false), 0);
  };

  const showCrewMembersFunc = () => {
    setShowCrewMembers(true);
  };

  return (
    <div className="crew-background">
      <button onClick={closeCard}>Back</button>
      <div className="shipInfo">
        <div className="crewContainer">
          <img
            className="image-item"
            src={currentImagePath}
            alt={currentCard}
          />
          <div className="shipDataDetails"></div>
        </div>
        <div className="pirate-font">
          <h3>Ship Crew:</h3>
          <p>Total Crew Required: {maxCrew}</p>
          {Object.keys(shipData).map((key) => (
            <div key={key}>
              <strong className="shipInfoName">{key}</strong>:{" "}
              {typeof shipData[key] === "object" && shipData[key] !== null ? (
                Array.isArray(shipData[key]) ? (
                  key === "crew" ? (
                    shipData[key].length > 0 ? (
                      <button onClick={showCrewMembersFunc}>
                        Show Crew Members
                      </button>
                    ) : (
                      "No crew members assigned"
                    )
                  ) : (
                    `${shipData[key].name}`
                  )
                ) : (
                  `${shipData[key].name}`
                )
              ) : (
                shipData[key]
              )}
            </div>
          ))}
          {showCrewMembers ? (
            <ShowCrew
              crew={shipData.crew}
              setShowCrewMembers={setShowCrewMembers}
            />
          ) : (
            ""
          )}

          {showAddCrewOption ? (
            <button onClick={addCrew} className="addCrew">
              Add Crew
            </button>
          ) : (
            ""
          )}

          {showBuildShipButton ? (
            <button onClick={buildShip}>Build Ship</button>
          ) : buildShipResponse ? (
            responseMessage
          ) : (
            ""
          )}
          {selectCrew ? (
            <AddCrew
              closeButton={closeButton}
              shipData={shipData}
              allData={allData}
              maxCrew={maxCrew}
              crewCount={crewCount}
              resetCounter={resetCounter}
              showCrewComp={setSelectCrew}
              setShowAddCrewOption={setShowAddCrewOption}
              assignedPirates={assignedPirates}
              setAssignedPirates={setAssignedPirates}
            />
          ) : (
            <div className="pirate-font">
              <p>Click on a pirate card to assign a role.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentCard;
