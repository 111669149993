import React from "react";
import LoadShip from "../LoadShip/LoadShip";
import StartMission from "../StartMission/StartMission";
import { useAuth } from "../../../AuthContext";
import SetSail from "../WebInterfaceCommands/SetSail";

const CurrentCommand = ({ playCommand }) => {
  const { user } = useAuth();
  return (
    <>
      {playCommand === "Build Ship" ? <LoadShip /> : ""}
      {playCommand === "Start Mission" && user === "looftee" ? (
        <StartMission />
      ) : (
        ""
      )}
      {playCommand === "Set Sail" && user === "looftee" ? <SetSail /> : ""}
    </>
  );
};

export default CurrentCommand;
