import axios from "axios";
import React, { useState } from "react";
import { useAuth } from "../../../AuthContext";
import OutcomeMessage from "./OutcomeMessage";

const CurrentMission = ({ currentMission, shipId, shipStats }) => {
  const [resultMessage, setResultMessage] = useState();
  const [showMissionButton, setShowMissionButton] = useState(true);
  const [eventHeading, setEventHeading] = useState("");
  const [infamyPoints, setInfamyPoints] = useState(0);
  const { apiUrl } = useAuth();
  const missionToBeDone = [
    "Plan Raid",
    "Precision Fire",
    "Decipher Treasure Map",
    "Treasure Spotter",
    "Food Expedition",
    "Create Bombs and Traps",
  ];
  const ImagePath = `/CardImages/missionImages/${currentMission
    .split(" ")
    .join("")
    .toLowerCase()}.png`;
  console.log(ImagePath);
  console.log(shipStats, shipStats.objects);
  const currentMissionHandler = () => {
    setShowMissionButton(false);
    const params = {
      mission: currentMission,
      shipId: shipId,
    };
    axios.post(`${apiUrl}/play_mission/`, params).then((response) => {
      console.log(response.data.result);
      const responseData = response.data.result;
      console.log(responseData.event_triggered);
      setInfamyPoints(responseData.infamy_points);
      if (!responseData.event_triggered) {
        setResultMessage(responseData.outcome_message);
      } else {
        console.log(responseData.event_data.event_data);
        setEventHeading(responseData.event_data.event_data.event_message);
        setResultMessage(responseData.event_data.event_data.crew_feeling);
      }
    });
  };

  const getProgressBarColor = (value) => {
    if (value < 25) return "#4caf50"; // Green
    if (value >= 25 && value < 50) return "#9c27b0"; // Purple
    if (value >= 50 && value < 75) return "#ff9800"; // Orange
    return "#f44336"; // Red
  };

  const closeButtonHandler = () => {
    setShowMissionButton(true);
    setEventHeading("");
    setInfamyPoints(0);
    setResultMessage("");
  };

  return (
    <div className="mission-image-container">
      <h3>{currentMission}</h3>
      <img className="mission-image" src={ImagePath} alt={currentMission} />
      {missionToBeDone.includes(currentMission) ? (
        <p>To be done in next update...</p>
      ) : showMissionButton ? (
        <button className="play-mission-button" onClick={currentMissionHandler}>
          {currentMission}
        </button>
      ) : (
        <OutcomeMessage
          resultMessage={resultMessage}
          closeButtonHandler={closeButtonHandler}
          eventHeading={eventHeading}
          infamyPoints={infamyPoints}
        />
      )}

      <h3>Ship Stats</h3>
      <div className="stat-container">
        {shipStats
          ? Object.entries(shipStats).map(([key, value]) => {
              if (key === "ship_id") return null;
              return (
                <div key={key} className="stat-item">
                  <strong>
                    {key
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (l) => l.toUpperCase())}
                    :
                  </strong>{" "}
                  <div className="progress-bar-container">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${value}%`,
                        backgroundColor: getProgressBarColor(value),
                      }}
                    ></div>
                  </div>
                </div>
              );
            })
          : "Data Not Available"}
      </div>
    </div>
  );
};

export default CurrentMission;
