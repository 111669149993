import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  // const [apiUrl, setApiUrl] = useState("http://127.0.0.1:8000");
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isActive, setActive] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  const apiUrl = "https://piratesaga.online";
  // const apiUrl = "http://127.0.0.1:8000";
  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        const response = await axios.get(`${apiUrl}/get_user_account/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const userName = response.data.message.userName;
        setIsAuthenticated(true);
        setUser(userName);
        setUserData(response.data.message);
        setActive(response.data.message.account_status);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUserData();

    const intervalId = setInterval(() => {
      fetchUserData();
    }, 10000000);

    return () => clearInterval(intervalId);
  }, [updateData]);

  const login = async (userName) => {
    let keychain = window.hive_keychain;

    keychain.requestSignBuffer(userName, "login", "Posting", (response) => {
      if (response.success === true) {
        // Send a request to the backend to generate a login token
        axios
          .post(`${apiUrl}/generate_login_token/`, {
            username: userName,
          })
          .then((response) => {
            // Extract the token from the response
            const token = response.data;

            // Store the token in local storage for future use
            localStorage.setItem("token", JSON.stringify(token));

            // Set the user and authentication status in the application state
            setUser(userName);
            setIsAuthenticated(true);
            return true;
          })
          .catch((error) => {
            // Handle errors during the request or response processing
            throw error;
          });
      }
    });
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isAuthenticated,
        userData,
        setIsAuthenticated,
        setUser,
        isActive,
        apiUrl,
        updateData,
        setUpdateData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
