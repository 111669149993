import React, { useState } from "react";
import AddCrewPirate from "./AddCrewPirate";
const AddCrew = ({
  closeButton,
  allData,
  shipData,
  maxCrew,
  crewCount,
  resetCounter,
  showCrewComp,
  setShowAddCrewOption,
  assignedPirates,
  setAssignedPirates,
}) => {
  const [raritySelected, setRaritySelected] = useState(false);
  const [piratesData, setPiratesData] = useState([]);

  const selectCrew = (rarity) => {
    const getPirateData = allData.filter((card) => {
      const cardType = JSON.parse(card.properties.stats).type;
      const cardRarity = JSON.parse(card.properties.nft).rarity;
      const currentRarityPirate =
        cardType === "pirate" && cardRarity === rarity;

      if (currentRarityPirate && !assignedPirates.includes(card._id)) {
        return { id: card._id, cardName: card.properties.name };
      }
      return false;
      // if (currentRarityPirate) {
      //   const currentPirate = { id: card._id, cardName: card.properties.name };
      //   piratesData.push(currentPirate);
      // }
    });
    setPiratesData(getPirateData);
    setRaritySelected(true);
  };
  return (
    <div>
      <div className="overlay">
        <div className="sell-container add-crew">
          <button className="close-button-crew" onClick={closeButton}>
            X
          </button>
          <h2 className="addcrew-h2">Add Crew</h2>
          {raritySelected ? (
            <AddCrewPirate
              pirateData={piratesData}
              maxCrew={maxCrew}
              crewCount={crewCount}
              resetCounter={resetCounter}
              shipData={shipData}
              showCrewComp={showCrewComp}
              setShowAddCrewOption={setShowAddCrewOption}
              setAssignedPirates={setAssignedPirates}
            />
          ) : (
            <div>
              <p
                onClick={() => selectCrew("Legendary")}
                className="rarity legendary"
              >
                Legendary Pirates
              </p>
              <p onClick={() => selectCrew("Epic")} className="rarity epic">
                Epic Pirates
              </p>
              <p onClick={() => selectCrew("Rare")} className="rarity rare">
                Rare Pirates
              </p>
              <p onClick={() => selectCrew("Common")} className="rarity common">
                Common Pirates
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCrew;
