import React, { useState } from "react";

const SelectShipRole = ({
  pirate,
  handlePirateSelection,
  shipRole,
  closeButton,
}) => {
  const [selectedRole, setSelectedRole] = useState("");

  const availableRoles = Object.keys(shipRole).filter(
    (role) => shipRole[role] === null || shipRole[role] === ""
  );

  const handleSelectChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleAssignRole = () => {
    if (selectedRole) {
      handlePirateSelection(pirate, selectedRole);
    }
  };

  return (
    <div className="overlay">
      <div className="select-ship-role sell-container">
        <button onClick={closeButton} className="close-button">
          Close
        </button>
        <h3>Select a Role for {pirate.name}</h3>
        <select value={selectedRole} onChange={handleSelectChange}>
          <option value="">Select a role</option>
          {availableRoles.map((role) => (
            <option key={role} value={role}>
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </option>
          ))}
        </select>
        <button onClick={handleAssignRole} disabled={!selectedRole}>
          Assign Role
        </button>
      </div>
    </div>
  );
};

export default SelectShipRole;
