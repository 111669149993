import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addCrew, setBuildShipButton } from "../../../../store";
const AddCrewPirate = ({
  pirateData,
  maxCrew,
  resetCounter,
  shipData,
  showCrewComp,
  setShowAddCrewOption,
  setAssignedPirates,
}) => {
  const crewCounter_ = useSelector((state) => state.crewCount);
  const dispatch = useDispatch();
  const [tempCrew, setTempCrew] = useState([]);
  const [availableCrew, setAvailableCrew] = useState(pirateData);
  const maxCrewToAdd = maxCrew - crewCounter_;
  const [crewCounter, setCrewCounter] = useState(Math.min(maxCrewToAdd, 20));
  const [showAddCrewButton, setShowAddCrewButton] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleAddCrew = (crewToAdd) => {
    dispatch(addCrew(crewToAdd));
  };
  useEffect(() => {
    if (pirateData.length > 0) {
      setAvailableCrew(pirateData);
    }
    if (resetCounter) {
      setCounter(0);
      setShowAddCrewButton(false);
    }
  }, [pirateData, resetCounter, crewCounter_]);
  const addCrewTemp = (pirate) => {
    const currentCount = counter + 1;
    setCounter(currentCount);
    if (tempCrew.length > 0) {
      setTempCrew((prevCrew) => [...prevCrew, pirate]);
    } else {
      setTempCrew([pirate]);
    }
    setAvailableCrew((prevData) =>
      prevData.filter((data) => data._id !== pirate.id)
    );

    if (currentCount >= crewCounter) {
      setAvailableCrew([]);
      setShowAddCrewButton(true);
    } else if (availableCrew.length === 1) {
      setShowAddCrewButton(true);
    }
  };

  const addShipCrew = () => {

    const tempCrewIds = tempCrew.map((crewMember) => parseInt(crewMember.id));
    setAssignedPirates((prevValues) => {
      return [...prevValues, ...tempCrewIds];
    });
    shipData.crew.push(...tempCrew);
    showCrewComp(false);

    handleAddCrew(tempCrewIds.length);
    setCrewCounter(Math.min(maxCrewToAdd, 20));
    if (crewCounter_ + tempCrewIds.length >= parseInt(maxCrew)) {
      setShowAddCrewOption(false);
      dispatch(setBuildShipButton(true));
    }
  };

  return (
    <div>
      <h4>
        <strong>
          <em>Card Name: Card Id</em>
          <p>Click on pirate name to select for the crew.</p>
        </strong>
      </h4>
      {showAddCrewButton ? <button onClick={addShipCrew}>Add Crew</button> : ""}

      <div className="pirate-list-container">
        {availableCrew.length > 0
          ? availableCrew.map((pirate) => (
              <p
                key={pirate._id}
                onClick={() =>
                  addCrewTemp({
                    name: pirate.properties.name,
                    id: pirate._id,
                  })
                }
              >
                {pirate.properties.name}: {pirate._id}
              </p>
            ))
          : "No Crew available to select"}
      </div>
      <div className="pirate-show-container">
        {tempCrew.length > 0 ? (
          tempCrew.map((el) => (
            <span key={el.id}>
              {el.name}: {el.id},
            </span>
          ))
        ) : (
          <p>No Crew Selected</p>
        )}
      </div>
    </div>
  );
};

export default AddCrewPirate;
