import React from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";

const SetSail = () => {
  const { apiUrl, userData } = useAuth();
  const discord_id = userData.discord_id;
  const setSail = async () => {
    const response = await axios.put(`${apiUrl}/set_sail/`, {
      discord_id: discord_id,
    });
    console.log(response);
  };

  return (
    <div>
      <p>Set Sail</p>
      <button onClick={setSail}>Set Sail</button>
    </div>
  );
};

export default SetSail;
