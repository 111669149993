import { createStore } from "redux";

const initialState = {
  crewCount: 0,
  showBuildShipButton: false,
};

// Action types
const ADD_CREW = "ADD_CREW";
const RESET_CREW_COUNT = "RESET_CREW_COUNT";
const SET_BUILD_SHIP_BUTTON = "SET_BUILD_SHIP_BUTTON";

// Action Creators
export const addCrew = (count) => ({
  type: ADD_CREW,
  payload: count,
});

export const resetCrewCount = () => ({
  type: RESET_CREW_COUNT,
});

export const setBuildShipButton = (show) => ({
  type: SET_BUILD_SHIP_BUTTON,
  payload: show,
});

// Reducer

const crewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CREW:
      return {
        ...state,
        crewCount: state.crewCount + action.payload,
      };
    case RESET_CREW_COUNT:
      return {
        ...state,
        crewCount: 0,
      };
    case SET_BUILD_SHIP_BUTTON:
      return {
        ...state,
        showBuildShipButton: action.payload,
      };
    default:
      return state;
  }
};

const store = createStore(crewReducer);

export default store;
