import React, { useState, useEffect } from "react";

const ShowRarity = ({ rarity, cardSet, clickHandler }) => {
  const [isCardSetEmpty, setIsCardSetEmpty] = useState(false);
  const filteredCards = cardSet.filter((card) => card.rarity === rarity);
  useEffect(() => {
    if (filteredCards.length === 0) {
      setIsCardSetEmpty(true);
    } else {
      setIsCardSetEmpty(false);
    }
  }, [filteredCards]);
  return (
    <>
      {isCardSetEmpty ? "" : <h2 className="rarity-heading">{rarity}</h2>}

      <div className="image-set">
        {cardSet
          .filter((card) => card.rarity === rarity) // Only show cards matching the rarity
          .map((card, index) => {
            const imagePath = `/CardImages/${card.rarity}/${card.name
              .split(" ")
              .join("_")}.png`;

            return (
              <div className="images-container" key={card.id}>
                <img
                  onClick={() =>
                    clickHandler(card.name, card.id, imagePath, card.rarity)
                  }
                  className="image-item"
                  src={imagePath}
                  alt={card.name}
                />
                <p className="cards-info">
                  {card.name}: Id: {card.id}
                </p>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ShowRarity;
