import React from "react";

const ShowCrew = ({ crew, setShowCrewMembers }) => {
  const closeCrewMembers = () => {
    setShowCrewMembers(false);
  };
  return (
    <div>
      <div className="overlay">
        <div className="show-crew-members">
          <button className="close-button-crew" onClick={closeCrewMembers}>
            Close
          </button>
          <p>Show Crew</p>
          <ul>
            {crew.map((el, index) => (
              <li key={index}>
                {index + 1}. {el.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ShowCrew;
