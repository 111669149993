import React from "react";
import Layout from "../../NavBar/Layout";
import GameCommands from "../GameCommands/GameCommands";

const PlayGame = () => {
  return (
    <Layout>
      <GameCommands></GameCommands>
    </Layout>
  );
};

export default PlayGame;
