import React, { useState } from "react";
import "./playmission.css";
import CurrentMission from "./CurrentMission";

const PlayMission = ({ selectedShip, shipStats }) => {
  const [currentMission, setCurrentMission] = useState("Boost Morale");
  const MissionList = {
    captain: ["Boost Morale", "Plan Raid"],
    quartermaster: ["Plunder Distribution", "Crew Discipline"],
    navigator: ["Chart Course", "Decipher Treasure Map"],
    boatswain: ["Repair Ship", "Create Bombs and Traps"],
    mastergunner: ["Precision Fire", "Armory Management"],
    surgeon: ["Heal Crew"],
    lookout: ["Eagle Eyes", "Treasue Spotter"],
    cook: ["Hearty Meals", "Food Expedition"],
  };
  // const selectedMission = MissionList.find(
  //   (mission) => mission.shipRarity === selectedShip.ship_rarity
  // );

  const missionClickHandler = (mission) => {
    setCurrentMission(mission);
  };
  return (
    <div className="mission-page">
      <div className="play-mission">
        <h1 className="mission-heading">Play Mission</h1>
        <div>
          {Object.entries(MissionList).map(([role, mission]) => (
            <div key={role}>
              <h3 className="role-heading">
                {role.charAt(0).toUpperCase() + role.slice(1)}
              </h3>
              <ul>
                {mission.map((mission, index) => (
                  <li
                    className="mission-list"
                    onClick={() => missionClickHandler(mission)}
                    key={index}
                  >
                    {mission}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      {currentMission !== null ? (
        <CurrentMission
          currentMission={currentMission}
          shipId={selectedShip.ship_id}
          shipStats={shipStats}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PlayMission;
