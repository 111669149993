import React from "react";

const LowResourceWarning = () => {
  return (
    <div className="no-resource-warning">
      <p>
        You do not have enough resources! Select any other ship or open some
        chests!
      </p>
    </div>
  );
};

export default LowResourceWarning;
