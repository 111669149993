import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";

const SelectShip = ({ shipClickHandler }) => {
  const { apiUrl } = useAuth();
  const [userShips, setUserShips] = useState([]);

  useEffect(() => {
    const fetchShipData = async () => {
      try {
        const res = await axios.get(`${apiUrl}/get_ship_data/`, {
          params: {
            hive_user_name: "thorlock",
          },
        });
        return res.data.result;
      } catch (err) {
        console.error("Error");
      }
    };
    fetchShipData().then((responseLocal) => {
      console.log(responseLocal);
      setUserShips(responseLocal);
    });
  }, [apiUrl]);

  return (
    <div>
      <p>Select Ship</p>
      {userShips.length > 0
        ? userShips.map((ship) => {
            const imagePath = `/CardImages/${ship["ship_rarity"]}/${ship[
              "ship_name"
            ]
              .split(" ")
              .join("_")}.png`;
            return (
              <div className="image-set" key={ship["ship_id"]}>
                <div className="images-container">
                  <img
                    className="image-item"
                    src={imagePath}
                    onClick={() => shipClickHandler(ship)}
                    alt={`${ship["ship_rarity"]}`}
                  />
                  <p>
                    {ship["ship_name"]} : {ship["ship_id"]}
                  </p>
                </div>
              </div>
            );
          })
        : "No Ships Available."}
    </div>
  );
};

export default SelectShip;
